.box {
  width: 150px;
  height: 60px;
  /* border: 1px solid red; */
  position: relative;
  overflow: hidden;
}

.box img {
  position: absolute;
  top: -120%; /* Initially hidden above the box */
  left: 50%; /* Initially centered horizontally */
  transform: translateX(-50%);
}

.box2 {
  width: 150px;
  height: 60px;
  /* border: 1px solid red; */
  position: relative;
  overflow: hidden;
}

.box2 img {
  position: absolute;
  top: 120%; /* Initially hidden above the box */
  left: 50%; /* Initially centered horizontally */
  transform: translateX(-50%);
}
