@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Work Sans";
  /* "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-family: "Inter";
}

/* .carousel .control-next.control-arrow  {
  position: absolute;
  background-color: red;
  bottom: 250px;
  height: 50px;
  border: 1px solid red !important;
} */

.mySwiper {
  color: black;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mySwiper .swiper-pagination-bullet {
  background: white;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  animation: imageAnimation 15s ease infinite;
  opacity: 0;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  35% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

.background-image:nth-child(1) {
  animation-delay: 0s;
}

.background-image:nth-child(2) {
  animation-delay: 5s;
}

.background-image:nth-child(3) {
  animation-delay: 10s;
}

/* Define the keyframes for the drawing animation */
@keyframes draw {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* Apply the animation to the circle */
.circle--dash {
  animation: draw 10s ease forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

@media screen and (min-width: 768px) {
  .timelineFirstdivFirstChild:after {
    content: "";
    /* top: 0; */
    width: 5px;
    height: 600px;
    /* background-color: red; */
    background: #007bff;

    position: absolute;
    top: 0;
    right: -5px;
  }

  .timelineFirstdivSecondChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 62px;
    left: -15px;
  }

  /* Add another circle */
  .timelineFirstdivSecondChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 58px;
    left: -19px; /* Position it to the right */
    z-index: 1000;
    animation: draw 10s ease forwards;
  }

  .timelineSeconddivSecondChild:after {
    content: "";
    width: 5px;
    height: 500px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: -8px;
  }

  .timelineSeconddivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    right: -13px;
    z-index: 100;
  }
  .timelineSeconddivFirstChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: -17px;
    z-index: 1000;
  }

  .timelineThirddivSecondChild:after {
    content: "";
    width: 5px;
    height: 500px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: -8px;
  }

  .timelineThirddivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    right: -13px;
    z-index: 100;
  }
  .timelineThirddivFirstChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: -17px;
    z-index: 1000;
  }

  .timelineFourthdivSecondChild:after {
    content: "";
    width: 5px;
    height: 500px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: -8px;
  }

  .timelineFourthdivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    right: -13px;
    z-index: 100;
  }
  .timelineFourthdivFirstChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: -17px;
    z-index: 1000;
  }

  .timelineFifthdivSecondChild:after {
    content: "";
    width: 5px;
    height: 500px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: -8px;
  }

  .timelineFifthdivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    right: -13px;
    z-index: 100;
  }
  .timelineFifthdivFirstChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: -17px;
    z-index: 1000;
  }

  .timelineSixthhdivSecondChild:after {
    content: "";
    width: 5px;
    height: 400px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: -8px;
  }

  .timelineSixthhdivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    right: -13px;
    z-index: 100;
  }
  .timelineSixthhdivFirstChild::after {
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: -17px;
    z-index: 1000;
  }
  .card2bg {
    /* background-image: url("./photos/Card2.png"); */
    background-image: url("/public/photos/bg2.png");
  }
}

@media screen and (max-width: 767px) {
  .timelineFirstdivFirstChild:before {
    content: "";
    /* top: 0; */
    width: 5px;
    height: 550px;
    /* background-color: red; */
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineFirstdivFirstChild::after {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 62px;
    left: 33px;
  }

  .timelineSeconddivSecondChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 33px; /* Position it to the right */
    z-index: 1000;
    animation: draw 10s ease forwards;
  }

  .timelineSeconddivSecondChild:after {
    content: "";
    width: 5px;
    height: 550px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineThirddivFirstChild::after {
    content: "";
    width: 5px;
    height: 550px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineThirddivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    left: 33px;
    z-index: 100;
  }

  .timelineFourthdivSecondChild:after {
    content: "";
    width: 5px;
    height: 550px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineFourthdivSecondChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    left: 33px;
    z-index: 100;
  }

  .timelineFifthdivFirstChild:after {
    content: "";
    width: 5px;
    height: 350px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineFifthdivFirstChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    left: 33px;
    z-index: 100;
  }

  .timelineSixthhdivSecondChild:after {
    content: "";
    width: 5px;
    height: 350px;
    background: #007bff;

    position: absolute;
    top: 0;
    left: 40px;
  }

  .timelineSixthhdivSecondChild::before {
    content: "";
    width: 20px;
    height: 20px;
    background: linear-gradient(100.04deg, #007bff 0%, #05dfad 99.02%);
    border-radius: 50%;
    position: absolute;
    top: 15;
    left: 33px;
    z-index: 100;
  }

  .card2bg {
    /* background-image: url("./photos/Card2.png"); */
    background-image: url("/public/photos/Card2.png");
  }
}
